<template>
    <v-card height="100%">
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit details</v-toolbar-title>

            <v-spacer />

            <v-btn small text @click="save">Save</v-btn>
        </v-toolbar>

        <rich-text-editor v-model="detailsValue" />
    </v-card>
</template>
<script>
import RichTextEditor from "../../components/RichTextEditor.vue";
import payments from "./paymentsAgent";

export default {
    components: { RichTextEditor },
    props: {
        id: {
            type: String,
            required: true,
        },
        details: {
            type: String,
            required: false,
        },
    },
    data: function () {
        return {
            detailsValue: this.details,
        };
    },
    methods: {
        save: async function () {
            const loading = this.$coreUi.loading();

            try {
                const payment = await payments.getPayment(this.id);

                const request = {
                    paymentRequestModel: {
                        parentSharedoId: payment.parentSharedoId,
                        supplierOdsId:
                            payment.aspectData.paymentRequest.supplierOdsId,
                        rechargeToOdsId:
                            payment.aspectData.paymentRequest.rechargeToOdsId,
                        details: this.detailsValue,
                        paymentRequestType: payment.sharedoTypeSystemName,
                    },
                };

                await payments.savePayment(this.id, request);
                this.close(true);
            } catch (error) {
                console.error(error);
            } finally {
                loading.dismiss();
            }
        },
        close: function (result) {
            this.$emit("close", result);
        },
    },
};
</script>
<style>
.tiptap-vuetify-editor {
    height: calc(100% - 94px);
}
.theme--light.v-sheet .tiptap-vuetify-editor > .v-card {
    height: 100%;
}
.tiptap-vuetify-editor__content {
    height: calc(100% - 74px);
}
.tiptap-vuetify-editor__content .ProseMirror {
    min-height: calc(100% - 40px);
}
</style>